import React from "react";
import { Text } from "@/components/ui/text";

import Guest from "@/public/static/images/empy-state/guest.svg";
import Activities from "@/public/static/images/empy-state/activities.svg";
import Events from "@/public/static/images/empy-state/events.svg";
import NoDataFound from "@/public/static/images/empy-state/no-data-found.svg";
import Image from "next/image";

type ImageKey = "guest" | "activities" | "events" | "default";

interface ImageMap {
  [key: string]: string;
}

const imageMap: Record<ImageKey, string> = {
  guest: Guest,
  activities: Activities,
  events: Events,
  default: NoDataFound
};

interface NoDataViewProps {
  title: string;
  description?: string;
  imageKey?: ImageKey;
}

const NoDataView: React.FC<NoDataViewProps> = ({
  title,
  description,
  imageKey = "default"
}: NoDataViewProps) => {
  const ImageToRender = imageMap[imageKey];

  return (
    <div className="flex flex-col items-center justify-center my-3xl">
      <Image src={ImageToRender} alt="No Data Available" className="h-[175px] w-[162px]" />
      <Text className="text-400 leading-400">{title}</Text>
      <Text className="text-[rgba(0, 0, 0, 0.60)] text-200 leading-200 ">{description}</Text>
    </div>
  );
};

export default NoDataView;
